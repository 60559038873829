 module controllers {
    export module master {
        interface ICustomsPartyUpdateScope extends ng.IScope {
        }

        interface ICustomsPartyUpdateParams extends ng.ui.IStateParamsService {
            antId: number;
        }

        export class customsPartyUpdateCtrl {

            static $inject = [
                "$scope",
                '$rootScope',
                'generalService',
                '$q',
                '$anchorScroll',
                '$uibModal',
                'bsLoadingOverlayService',
                '$stateParams',
                'entityService',
                '$state',
                '$timeout',
                '$transitions',
                'classificationValueService',
                'documentRepositoryService',
                'lookupService',
                "supplierService",
                "localeService",
                'customsPartyService',
                'authorisedDealerService',
                "menuService"
            ];


            antId: number;

            breadCrumbDesc: string;

            isLoadingDetail: boolean = false;
            showAll: boolean = false;

            selectedTab: number = 0;

            documentRepository: interfaces.documentManagement.IDocumentRepository;
            IsDisabled: boolean = false;

            apiPaymentHistoryList: uiGrid.IGridApi;
            selectedRow: uiGrid.IGridRow;

            paginationOptions = {
                pageNumber: 1,
                pageSize: 25
            };

            customsParty: interfaces.master.ICustomsPartyMaster;

            createPaymentRight: boolean = false;
            editPaymentRight: boolean = false;   
            removePaymentRight: boolean = false;
            
      
            constructor(
                private $scope: ICustomsPartyUpdateScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $anchorScroll: ng.IAnchorScrollService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private bsLoadingOverlayService,
                private $stateParams: ICustomsPartyUpdateParams,
                private entityService: interfaces.applicationcore.IEntityService,
                private $state: ng.ui.IStateService,
                private $timeout: ng.ITimeoutService,
                public $transitions: ng.ui.core.ITransition,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                public documentRepositoryService: interfaces.documentManagement.IDocumentRepositoryService,
                public lookupService: interfaces.applicationcore.ILookupService,
                private supplierService: interfaces.master.ISupplierService,
                private localeService: interfaces.applicationcore.ILocaleService,
                private customsPartyService: interfaces.master.ICustomsPartyService,
                public authorisedDealerService: interfaces.tariff.IAuthorisedDealerService,
                private menuService: interfaces.applicationcore.IMenuService,
            ) {

                this.antId = $stateParams.antId;

                var loadPromises: ng.IPromise<any>[] = [];

                loadPromises.push(this.LoadCustomsParty(this.antId));
                loadPromises.push(this.getCreatePaymentRight());
                loadPromises.push(this.getEditPaymentRight());
                loadPromises.push(this.getRemovePaymentRight());

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'ant.update'
                },
                    () => {
                        return $q.all(loadPromises);
                    });
                
            }

            
            getCreatePaymentRight() {
                this.createPaymentRight = false;
                return this.menuService.getGTSConnectMenuItem(717).get((result: interfaces.applicationcore.IMenu) => {
                    if (result && result.name) { 
                        this.createPaymentRight = true;
                    }
                }).$promise;
            }

            getEditPaymentRight() {
                this.editPaymentRight = false;
                return this.menuService.getGTSConnectMenuItem(718).get((result: interfaces.applicationcore.IMenu) => {
                    if (result && result.name) {
                        this.editPaymentRight = true;
                    }
                }).$promise;
            }
            
            getRemovePaymentRight() {
                this.removePaymentRight = false;
                return this.menuService.getGTSConnectMenuItem(719).get((result: interfaces.applicationcore.IMenu) => {
                    if (result && result.name) {
                        this.removePaymentRight = true;
                    }
                }).$promise;
            }      



            LoadCustomsParty(Id : number)
            {
                return this.customsPartyService.getCustomsParty().query({ Id: Id }, (data: interfaces.master.ICustomsPartyMaster) => {

                    this.customsParty = data;

                    this.breadCrumbDesc = this.customsParty.CompanyCode;

                    this.isLoadingDetail = true;
                    this.$timeout(() => {
                        //this.gvwInvoices.data = this.payment.InvoiceList;
                        //this.gvwLC.data = this.payment.LetterOfCreditList;
                        //this.loadControls();
                        this.gvwPaymentHistory.data = this.customsParty.PaymentHistory;
                        this.gvwPaymentHistory.totalItems = this.customsParty.PaymentHistory.length;
                        this.isLoadingDetail = false;
                    });
                }
                    , (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
            }

            downloadPaymentHistoryToExcel() {
                this.isLoadingDetail = true;


                this.customsPartyService.getPaymentHistoryExcel(this.customsParty.Id).then(() => {
                    this.isLoadingDetail = false;
                }, (data) => {
                    this.isLoadingDetail = false;
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                });
            }

            calculateCurrentBalance(){

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'ant.update'
                },
                    () => {
                        return this.customsPartyService.calculateCurrentBalance(this.customsParty.Id).save({},
                            (result: interfaces.applicationcore.IMessageHandler) => {
                                this.generalService.displayMessageHandler(result);                                     
                            }, (failureMessage) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureMessage.data);
                                
                            }).$promise;
                    });
            }

            editPayment(accountPaymentId: number){
 

                    this.$uibModal.open({
                        animation: true,
                        size: "xlg",
                        template: `
                                        <gts-account-payment customs-party-id="AccountPaymentModalCtrl.customsPartyId" 
                                                            account-payment-id="AccountPaymentModalCtrl.accountPaymentId"
                                                            close="AccountPaymentModalCtrl.ok()"
                                        >
                                        </gts-account-payment>                            
                                    `,
                        controller: class diDetailCostingCtrl {
                            customsPartyId: number;
                            accountPaymentId: number;
    
                            constructor($scope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService, private items, private bsLoadingOverlayService,
                                private deliveryInstructionService: interfaces.purchase.IDeliveryInstructionService, private generalService) {
                                this.customsPartyId = items.customsPartyId;
                                this.accountPaymentId = items.accountPaymentId;
                            }
    
                            ok() {
                                this.$uibModalInstance.close();
                            }
   
                        },
                        controllerAs: 'AccountPaymentModalCtrl',
                        resolve: {
                            items: () => {
                                return {
                                    customsPartyId: this.antId,
                                    accountPaymentId: accountPaymentId
                                };
                            }
                        }
                    }).result.then((result) => {
                        
                            this.LoadCustomsParty(this.antId)
                        
                    });

            
            }

            removePayment() {
                if (this.apiPaymentHistoryList.selection.getSelectedRows().length > 0) {

                    this.generalService.displayConfirmationBox("Confirm Remove", "Are you sure you want to remove this Payment?").then((result) => {
                        var selectedRow: interfaces.master.PaymentHistory = this.apiPaymentHistoryList.selection.getSelectedRows()[0];
                        if (result && (!selectedRow.AccountPaymentId || selectedRow.AccountPaymentId <= 0)) {
                            
                        } else if (result) {
                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'ant.update'
                            },
                                () => {
                                    return this.customsPartyService.removePayment(this.customsParty.Id,selectedRow.AccountPaymentId ).save({},
                                        (result: interfaces.applicationcore.IMessageHandler) => {
                                            this.generalService.displayMessageHandler(result);                                     
                                        }, (failureMessage) => {
                                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureMessage.data);
                                            
                                        }).$promise;
                                    
                                });
                        }
                    });

                } else {
                    this.generalService.displayMessage("Please select a Payment to remove", Enum.EnumMessageType.Warning);
                }
            }

            addPayment(){
                 
                    this.$uibModal.open({
                        animation: true,
                        size: "xlg",
                        template: `
                                        <gts-account-payment customs-party-id="AccountPaymentModalCtrl.customsPartyId" 
                                                            account-payment-id="AccountPaymentModalCtrl.accountPaymentId"
                                                            close="AccountPaymentModalCtrl.ok()"
                                        >
                                        </gts-account-payment>                            
                                    `,
                        controller: class diDetailCostingCtrl {
                            customsPartyId: number;
                            accountPaymentId: number;
    
                            constructor($scope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService, private items, private bsLoadingOverlayService,
                                private deliveryInstructionService: interfaces.purchase.IDeliveryInstructionService, private generalService) {
                                this.customsPartyId = items.customsPartyId;
                                this.accountPaymentId = items.accountPaymentId;
                                console.log("this.customsPartyId",this.customsPartyId);
                                console.log("this.accountPaymentId",this.accountPaymentId);
                            }
    
                            ok() {
                                this.$uibModalInstance.close();
                            }
   
                        },
                        controllerAs: 'AccountPaymentModalCtrl',
                        resolve: {
                            items: () => {
                                return {
                                    customsPartyId: this.antId,
                                    accountPaymentId: null
                                };
                            }
                        }
                    }).result.then((result) => {
                        
                            this.LoadCustomsParty(this.antId)
                        
                    });
               
            }

            gvwPaymentHistory: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                //useExternalFiltering: true,
                enableCellEdit: false,
                useExternalSorting: true,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                enablePagination: false,
                enablePaginationControls: false,
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                showGridFooter: true,
                enableHorizontalScrollbar: 2,
                enableVerticalScrollbar: 1,
                rowEditWaitInterval: -1,             
                onRegisterApi: (gridApi) => { this.registerGridApi(gridApi) },
                columnDefs: [{
                    name: "EDIT",
                    displayName: "",
                    enableFiltering: false,
                    cellTemplate: `
                    <div> 
                        <button type="button" ng-click="grid.appScope.customsPartyUpdateCtrl.editPayment(row.entity.AccountPaymentId)" 
                        ng-disabled = "!grid.appScope.customsPartyUpdateCtrl.editPaymentRight"
                        class="btn btn-default btn-sm">
                            <span class="fa fa-pencil"></span>
                        </button>
                    </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35
                }, 
                {
                    name: "PaymentReference",
                    displayName: "Payment Reference",
                    field: "PaymentReference",
                    width: 200,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                },{
                    name: "PaymentDate",
                    displayName: "Payment Date",
                    cellFilter: 'momentDateFilter:"YYYY/MM/DD"',
                    field: "PaymentDate",
                    width: 120,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    type: "string",
                    cellClass: 'text-right',
                    enableCellEdit: false,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: 'YYYY/MM/DD'}}</div>`,                 
                    enableCellEditOnFocus: false                            
                }, {
                    name: "AlternateReference",
                    displayName: "Alternate Reference",
                    field: "AlternateReference",
                    width: 200,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                },
                {
                    name: "BankName",
                    displayName: "Bank Name",
                    field: "BankName",
                    width: 220,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                },
                {
                    name: "PaymentAmount",
                    displayName: "Payment Amount",
                    field: "PaymentAmount",
                    width: 120,
                    cellClass: 'text-right',
                    cellFilter: "number: 2",
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                },        
                {
                    name: "Currency",
                    displayName: "Currency",
                    field: "CurrencyCode",
                    width: 80,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                },
                {
                    name: "Active",
                    displayName: "Active",
                    field: "Active",                    
                    enableFiltering: false,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    cellTemplate: `<input type="checkbox" disabled   ng-model="row.entity.Active">`,                                        
                    filter: {
                        condition: (searchTerm, cellValue, ) => {
                            if (searchTerm)
                                return cellValue === true;
                            else
                                return true;
                        }
                    } ,                         
                    width: 60
                }                     
               ]
            };

            registerGridApi(gridApi: uiGrid.IGridApi) {
                this.apiPaymentHistoryList = gridApi;
                this.apiPaymentHistoryList.pagination.on.paginationChanged(this.$scope, (newPage, pageSize) => { this.CIListPageChange(newPage, pageSize) });

                this.apiPaymentHistoryList.selection.on.rowSelectionChanged(this.$scope, (selected) => { this.selectedRow = selected; });

                this.$timeout(() => {
                    if (this.selectedRow)
                        this.apiPaymentHistoryList.selection.selectRow(this.selectedRow.entity);
                });
            }
            CIListPageChange(newPage, pageSize) {
                this.paginationOptions.pageNumber = newPage;
                this.paginationOptions.pageSize = pageSize;
            }

            Close() {
                this.$state.go("^");
            }

            saveCustomsParty() {

                
                return this.customsPartyService.save().save(this.customsParty, (data: interfaces.applicationcore.IMessageHandler) => {
                     this.generalService.displayMessageHandler(data);
                                   
                  
                 }, (errorResponse) => {
                     this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
 
                 }).$promise;
             };
 
              Save() {
                 this.bsLoadingOverlayService.wrap({
                     referenceId: 'ant.update'
                 }, () => {
                     return this.saveCustomsParty().then((result) => {
                         this.generalService.displayMessageHandler(result);
                         if (!result.HasErrorMessage) {
                             this.$timeout(() => {
                                 this.bsLoadingOverlayService.wrap({
                                     referenceId: 'ant.update'
                                 }, () => {
                                     return this.LoadCustomsParty(this.antId);
                                 });
                             });
                         }
                     });
                 });
             }
 
             SaveClose() {
                 this.bsLoadingOverlayService.wrap({
                     referenceId: 'ant.update'
                 }, () => {
                     return this.saveCustomsParty().then(() => {
                         this.Close();
                     }, (failureData) => {
                         this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                         this.$anchorScroll("topAnchor");
                     });
                 });
             }

          
        };

        angular.module("app").controller("customsPartyUpdateCtrl", controllers.master.customsPartyUpdateCtrl);
    }
}
